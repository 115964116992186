.case-studies-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(100% - 1rem);
  overflow-x: hidden;
  position: relative;
  padding: 0 var(--side-padding);
  padding-top: calc(var(--header-height));
}

.case-studies-inner {
  display: flex;
  flex-direction: row;
  position: relative;
  min-height: 100vh;
  /* margin-top: 2.5rem; */
  padding-top: 2.5rem;
  width: 100%;
  /* border-top: 1.5px solid var(--whiteGrey); */
}

.case-studies-inner .case-studies-fixed {
  position: sticky;
  top: calc(0rem + var(--header-height));
  width: 35%;
  padding-right: 3rem;
  margin-right: 2rem;
  /* border-right: 1.5px solid var(--whiteGrey); */
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 2 * var(--header-height));
}

.case-study-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  grid-gap: 1.5rem;
  padding-right: 3rem;
}

.grid-desc {
  color: var(--tan);
  opacity: 0.7;
  font-size: 0.9rem;
  text-transform: lowercase;
  letter-spacing: 0.2px;
}

.case-studies-fixed li:liactive {
  background-color: var(--primary);
}

.case-studies-fixed li {
  margin-bottom: 0.4rem;
}

.case-studies-fixed li a {
  text-decoration: none;
  letter-spacing: 0.3px;
  font-size: 13.5px;
  /* color: var(--grey); */
}
.case-studies-fixed li a:hover {
  color: var(--tan);
}

.case-studies-inner .case-studies-content {
  flex: 1;
}

.case-studies-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0;
  overflow: hidden;
  max-height: 250px;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  background-color: var(--faintGrey);
}

.case-studies-item:not(.case-studies-item:first-child) {
  /* border-top: 1.5px solid var(--whiteGrey); */
}
/* 
.case-studies-item .case-studies-item-top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.case-studies-item-top span {
  display: flex;
  align-items: center;
  padding: 1rem 0;
}

.case-studies-item-top span .round-button {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--faintGrey);
  cursor: pointer;
  transition: 0.25s ease-out;
}

.case-studies-item-top span .round-button:hover {
  background-color: var(--whiteGrey);
  cursor: pointer;
  transition: 0.25s ease-out;
}

.case-studies-item-top span .text {
  font-size: 0.9rem;
  font-family: PJSBold;
  margin: 0 1rem;
  letter-spacing: 0.4px;
} */

.case-studies-item .case-studies-item-img {
  width: 40%;
  height: 250px;
}

.case-studies-item .case-studies-item-img img {
  /* object-fit: contain; */
  height: 100%;
}

.case-studies-item .case-studies-item-content {
  padding: 1.5rem;
  flex: 1;
  max-height: 250px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.case-studies-item .content {
  font-size: 14px;
  letter-spacing: 0.4px;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
}

.case-studies-item .content ul {
  list-style: disc;
  padding-inline-start: 20px;
}

.case-studies-item li {
  color: var(--tan);
  line-height: 1.4;
  margin-bottom: 1rem;
  opacity: 0.8;
}

.case-studies-item img {
  height: 100%;
  object-position: center;
  object-fit: cover;
  border-radius: var(-border-radius-lg);
  /* margin-bottom: 2rem; */
}

.case-study-overlay {
  position: absolute;
  z-index: 99999;
  background-color: #000000aa;
  width: 100vw;
  height: 100vh;
  left: 0;
  /* position: fixed; */
  top: 0;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.case-study-overlay .case-study-overlay-inner {
  width: 100%;
  min-height: 100%;
  position: relative;
}

.case-study-overlay .case-study-white {
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  /* margin-top: var(--header-height); */
  width: 50%;
  padding: 2rem;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  background-color: white;
  z-index: 9999999;
}

.case-study-overlay.is-active {
  visibility: visible;
}

.case-study-white ul {
  list-style-type: disc;
  padding-inline-start: 20px;
  margin: 1.5rem 0;
}

@media screen and (max-width: 600px) {
  .case-studies-inner .case-studies-fixed {
    width: 100%;
    position: initial;
    height: unset;
  }
  .case-studies-mobile {
    box-shadow: -2px 0 5px #0000003a;
    width: 100%;
    transform: translateX(calc(-1 * var(--side-padding)));
    position: fixed;
    z-index: 9999;
    padding: 10px var(--side-padding);
    padding-bottom: 1rem;
    border-top: 1.5px solid var(--faintGrey);
    display: flex;
    bottom: 0;
    background-color: white;
    flex-direction: column;
  }
  .case-studies-inner {
    flex-direction: column;
  }
  .case-studies-inner .case-studies-content {
    margin-top: 2rem;
  }
  .case-studies-item {
    /* height: 500px; */
    max-height: 500px;
    /* position: fixed; */
    flex-direction: column;
  }
  .case-studies-item .case-studies-item-img {
    width: 100%;
    height: unset;
  }
  .case-studies-item .case-studies-item-content {
    width: 100%;
    max-height: unset;
    padding: var(--side-padding);
  }
  .case-studies-item .case-studies-item-img img {
    margin-bottom: 0;
  }
  .case-study-overlay {
    position: fixed;
  }
  .case-study-overlay .case-study-overlay-inner .case-study-white {
    width: 100%;
    height: 80%;
    top: 20vh;
    padding: var(--side-padding);
  }
}

.gallery-container {
  padding-top: calc(var(--header-height) + 0rem);
  padding-bottom: 5rem;
  position: relative;
}

.gallery-grid {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(3, 1fr);
}

.gallery-grid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  transition: 0.25s ease-out;
}

.gallery-grid img:hover {
  transform: scale(1.02);
  transition: 0.25s ease-out;
}

.gallery-overlay {
  width: 100%;
  height: 100%;
  /* position: sticky; */
  position: fixed;
  z-index: 9999;
  background-color: #000000aa;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: visibility 0.35s ease-out;
}

.gallery-overlay .gallery-image-view {
  /* background-color: white; */
  border-radius: 5px;
  /* overflow: hidden; */
  position: relative;
  min-width: 30%;
  min-height: 30%;
  max-width: 80%;
  max-height: 80%;
}

.gallery-overlay .gallery-image-view img {
  width: 100%;
  border-radius: 5px;
  height: 100%;
  object-fit: cover;
}

.gallery-overlay .close-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  transition: 0.25s ease-out;
  transform: rotate(45deg);
  color: white;
  cursor: pointer;
}

.gallery-overlay .next-icon {
  position: absolute;
  transition: 0.25s ease-out;
  top: 50%;
  right: -2rem;
  transform: translateY(-50%);
  color: white;
  cursor: pointer;
}

.gallery-overlay .prev-icon {
  position: absolute;
  transition: 0.25s ease-out;
  top: 50%;
  left: -2rem;
  transform: translateY(-50%);
  color: white;
  cursor: pointer;
}

.prev-icon:hover .next-icon:hover {
  transform: translateY(-50%) scale(1.02);
}

.close-icon:hover {
  transform: rotate(45deg) scale(1.02);
}

@media only screen and (max-width: 600px) {
  .gallery-grid {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;
  }
  .gallery-overlay .prev-icon {
    left: 0.5rem;
  }
  .gallery-overlay .next-icon {
    right: 0.5rem;
  }
  .gallery-overlay .gallery-image-view {
    max-width: calc(100% - 2 * var(--side-padding));
    min-width: calc(100% - 2 * var(--side-padding));
  }
}

.stat-box {
  width: calc(100% - 2 * var(--side-padding));
  background-color: var(--primary);
  padding: 3rem;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  margin: 3rem var(--side-padding);
}

.stat-box .stat-box-logos {
  margin-top: 2.5rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 1rem;
  place-items: center;
}

@media only screen and (max-width: 600px) {
  .stat-box {
    margin: 1rem var(--side-padding);
    padding: var(--side-padding);
  }
  .stat-box .stat-box-logos {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 1.5rem;
    grid-gap: 1.5rem;
  }
  .h4 {
    font-size: 18px;
    line-height: 25px;
  }
}

.header-outer-container {
  width: 100%;
  height: var(--header-height);
  position: fixed;
  top: 0;
  background-color: rgb(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  box-shadow: 0px 0.8px 1px rgba(180, 187, 189, 0.2);
  backdrop-filter: blur(10px);
}

.header-outer-container.no-bg {
  background-color: unset;
  box-shadow: none;
}

.header-inner-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 var(--side-padding);
  height: 100%;
}

.header-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.header-left .header-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header-left .header-logo .webeye-logo {
  width: 36px;
  height: 36px;
  border-radius: var(--border-radius-sm);
  background-color: var(--lightGrey);
  margin-right: 1rem;
}

.header-left .header-logo p.webeye-title {
  color: var(--tan);
  font-family: PJSBold;
  font-style: normal;
  font-size: 18px;
  line-height: 28px;
  white-space: nowrap;
  letter-spacing: 0.015em;
}

.header-left ul {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  height: 100%;
  position: relative;
  /* padding-left: 1.5rem; */
}

.header-left ul li {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  cursor: pointer;
}

.header-left ul li a {
  color: var(--grey);
  font-size: 14.5px;
  line-height: 150%;
  letter-spacing: 0px;
  font-weight: 300;
  margin: 0 1rem;
  letter-spacing: 0.01em;
  transition: 0.25s ease-out;
}

.header-left ul li svg {
  color: var(--grey);
}

.header-left ul li:hover a,
.header-left ul li:hover svg,
.header-left ul li.active-link a,
.header-left ul li.active-link svg {
  color: var(--primary);
  transition: 0.25s ease-out;
}

.header-submenu {
  max-width: 30vw;
  background-color: white;
  box-shadow: 1px 1px 20px #0000001b;
  position: absolute;
  padding: 0.5rem 0;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  border-radius: var(--border-radius-sm);
  transform: translateY(calc(var(--header-height) + 0.5rem)) translateX(3rem);
}

.header-submenu ul {
  padding-left: 0;
}

.header-submenu li a {
  line-height: 1.2;
  font-size: 10px;
}

@media only screen and (max-width: 600px) {
  .header-left .header-logo p.webeye-title {
    font-size: 13px;
  }
  .header-inner-container.mb-open {
    background-color: white;
  }
  .header-right {
    display: none;
  }
  .header-left {
    width: 100%;
    justify-content: space-between;
  }

  .header-mobile-cont.header-mobile-cont-closed {
    height: 0;
    overflow: hidden;
  }

  .header-mobile-cont.header-mobile-cont-closed .header-mobile-cont-inner {
    visibility: hidden;
    transition: 0.5s ease-out;
    height: 0;
  }
  .header-mobile-cont {
    width: 100%;
    height: 100vh;
    background-color: #0000004a;
    backdrop-filter: blur(5px);
    position: fixed;
    top: var(--header-height);
    z-index: 9999;
  }
  .header-mobile-cont .header-mobile-cont-inner {
    width: 100%;
    transition: 0.5s ease-out;
    visibility: visible;
    height: 50vh;
    display: flex;
    flex-direction: column;
    padding: var(--side-padding);
    background-color: white;
    overflow: hidden;
  }
  .header-mobile-cont ul li a {
    line-height: 3;
    font-size: 0.9rem;
  }
  .header-mobile-cont.header-mobile-cont-closed ul {
    visibility: hidden;
  }
  .header-mobile-cont-inner ul {
    visibility: visible;
  }
}

.not-found-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  padding-top: calc(var(--header-height) + 2rem);
}

.not-found-container .normal-text-lg {
  font-size: 15px;
  font-family: PJSBold;
}

.go-home {
  font-family: PJSBold;
  letter-spacing: 0.3px;
}

.green-box {
  background-color: #27ba6220;
}

.desc {
  font-size: 13.5px;
}

@media only screen and (max-width: 600px) {
  .not-found-container {
    padding: 2rem var(--side-padding);
  }
  .green-box {
    width: 2.5rem !important;
    height: 2.5rem !important;
  }
  .desc {
    display: none;
  }
}

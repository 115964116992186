.landing-testimonial-container-grid .landing-testimonial-item {
  width: 100%;
  border: 1px solid var(--whiteGrey);
  border-radius: var(--border-radius-lg);
  padding: 1.8rem;
  margin-bottom: 2rem;
}

.landing-testimonial-item .landing-testimonial-name {
  font-family: PJSBold, sans-serif;
}

.landing-testimonial-item .landing-testimonial-content {
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: -webkit-box;
}

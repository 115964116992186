.testimonial-container {
  padding: 0rem;
  padding-top: calc(var(--header-height) + 0rem);
}

.testimonial-container .testimonial-hero {
  background-color: var(--primary);

  /* background-color: var(--tan); */
  min-height: 55vh;
  margin: 3rem var(--side-padding);
  padding: 4rem;
}

/* .dotted-underline {
  color: rgb(14, 107, 14);
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-style: dashed;
  transition: 0.2s ease-out;
  text-decoration-color: rgb(14, 107, 14);
} */

/* .dotted-underline:hover {
  color: white;
  text-decoration-color: white;
  opacity: 0.6;
  transition: 0.2s ease-out;
} */

.client-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* grid-gap: 1.5rem; */
  margin-top: 3rem;
}

.client-item-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  aspect-ratio: 2/1;
  position: relative;
}

.client-item-image .overlay {
  background: rgba(39, 62, 84, 0.82);
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  transition: visibility 0.4s ease-out;
}

.client-item .normal-text-lg {
  font-family: PJSBold;
  font-size: 1.1rem;
}

.url-disabled {
  pointer-events: none;
  cursor: default;
}

.marquee-content {
  display: flex;
  animation: scrolling 30s linear infinite;
}

.marquee-inner {
  position: absolute;
  width: calc(100% + 2rem);
  margin-left: -1rem;
  top: 0;
  height: 100%;
  z-index: 10;
  background: linear-gradient(to right, #27ba62, #00000000 90%, #27ba62);
}

@keyframes scrolling {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translatex(-144vw);
  }
}

.marquee-content img {
  flex: 0 0 8rem;
  margin: 0 1rem;
}

@media only screen and (max-width: 600px) {
  .testimonial-container {
    height: unset;
  }
  .testimonial-container .testimonial-hero {
    margin-top: 1rem;
    margin-bottom: 1rem;
    flex-direction: column;
    padding: 1.2rem;
  }
  .testimonial-container .testimonial-hero .yt-video {
    width: 100%;
    height: unset;
  }
  .testimonial-container .testimonial-hero .testimonial-text {
    margin-top: 1rem;
  }
  .testimonial-container .testimonial-hero .testimonial-text .text-xl {
    font-size: 1.2rem;
  }
  .testimonial-container .testimonial-hero .testimonial-text .normal-text-sm {
    margin-top: 0;
  }
  .testimonial-container .testimonial-hero .testimonial-text .my-6 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }
  .testimonial-container .landing-testimonial-item {
    margin: 0.5rem 0;
  }
  .testimonial-container .h1 {
    font-size: 1.4rem;
    line-height: 1.6;
  }
  .testimonial-container .client-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

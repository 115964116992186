.about-container {
  padding: 0 0 3rem 0;
  display: flex;
  flex-direction: column;
}

.about-container .about-hero {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  padding: 0 var(--side-padding);
  padding-top: calc(var(--header-height) + 3rem);
  display: flex;
  justify-content: space-between;
  /* flex-direction: column; */
}

.about-hero .about-hero-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 80vh;
  background: url("../../assets/images/landing/gradientbg2.svg") 100% no-repeat;
}

.about-hero .about-hero-left {
  display: flex;
  z-index: 2;
  flex-direction: column;
  padding: 1rem 0;
  flex: 1;
  padding-right: 4rem;
}

.about-hero-left .text-4xl {
  font-family: PJSXBold;
}

.about-hero .about-hero-right {
  display: flex;
  z-index: 2;
  width: 30%;
  flex-direction: column;
  padding-bottom: 4rem;
}

.video-covering {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}

.video-covering .video-overlay {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-overlay .play-button {
  width: 4rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  height: 4rem;
  transition: 0.25s ease-out;
  cursor: pointer;
}

.video-overlay .play-button:hover {
  transform: scale(1.03);
  transition: 0.25s ease-out;
}

.video-overlay .play-button img {
  width: 30%;
  object-fit: cover;
  cursor: pointer;
  margin-left: 5px;
  transition: 0.25s ease-out;
}

.video-overlay .play-button:hover img {
  transform: scale(1.03);
  transition: 0.25s ease-out;
}

.about-hero .about-hero-right video {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  border-radius: 6px;
}

.about-hero .works-demo {
  width: 22rem;
  position: absolute;
  bottom: 1.5rem;
  left: 50%;
  transform: translateX(-8rem);
}

.feats {
  width: 100%;
  z-index: 2;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 2rem;
  row-gap: 3rem;
}

.feats .about-hero-feat {
  display: flex;
  flex-direction: column;
}
.feats .about-hero-feat .about-hero-feat-img {
  width: 100%;
  height: 216px;
  background-color: var(--faintGrey);
  border-radius: 5px;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  justify-content: center;
}

.feats .about-hero-feat .about-hero-feat-img img {
  width: 100%;
}

.about-hero-feat .text-md {
  font-family: PJSBold;
}

.feat-desc {
  font-size: 0.88rem;
  font-family: PJSMedium;
  color: var(--grey);
  line-height: 1.8;
  letter-spacing: 0.25px;
}

.faq-card {
  width: 100%;
  display: flex;
  margin-bottom: 3rem;
}

.faq-card .question {
  font-size: 1.1rem;
  font-weight: 600;
  font-family: "PJSBold";
  color: var(--tan);
  line-height: 1.8;
  width: 45%;
  margin-right: 3rem;
  letter-spacing: 0px;
}

.faq-card .answer {
  font-size: 0.9rem;
  font-family: PJSMedium;
  color: var(--darkGrey);
  line-height: 1.8;
  flex: 1;
  letter-spacing: 0.25px;
}

@media only screen and (max-width: 600px) {
  .about-container .about-hero {
    height: unset;
    flex-direction: column;
    padding-top: calc(var(--header-height) + 1.5rem);
  }
  .about-container .about-hero .about-hero-bg {
    height: 100vh;
  }
  .about-container .about-hero .about-hero-left {
    padding: 1rem 0;
    padding-top: 0;
    margin-top: 0;
    justify-content: space-around;
    flex: none;
    height: calc(100vh - var(--header-height));
  }
  .about-container .about-hero .about-hero-left .text-4xl {
    font-size: 1.4rem;
  }
  .about-container .about-hero .about-hero-right {
    width: 100%;
    margin-top: var(--side-padding);
    padding-bottom: 1rem;
    padding: 0 0 1rem 0;
  }
  .feats {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 2rem;
  }
  .about-hero .works-demo {
    top: calc(100vh + 1rem);
    display: none;
    left: 50%;
    bottom: unset;
    width: 50%;
    transform: translateX(-50%);
  }
  .about-hero .about-hero-left .flex.items-center {
    flex-direction: column;
  }
  .about-hero .about-hero-left .flex.items-center a {
    width: 100%;
    margin: 5px 0;
  }
  .about-hero .about-hero-left .flex.items-center button {
    width: 100%;
  }
  .about-hero .about-hero-left svg {
    margin-left: 50%;
    transform: translateX(-50%);
    margin-bottom: 2rem;
  }
  .faq-card {
    flex-direction: column;
  }
  .faq-card .question {
    width: 100%;
    margin-right: 0;
    font-size: 0.95rem;
    margin-bottom: 0.6rem;
  }
  .faq-card .answer {
    font-size: 14px;
    font-family: PJSMedium;
    color: var(--darkGrey);
    line-height: 1.8;
    flex: 1;
    letter-spacing: 0.25px;
  }
}

@import "../../globals.css";

.footer-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* padding: 2rem var(--side-padding) 0; */
  background-color: var(--faintGrey);
  /* border-top: 2px solid var(--whiteGrey); */
}

.footer-container .footer-top {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 2rem 0 2rem 0;
  padding: 2rem var(--side-padding) 0;
}

.footer-top .footer-top-left {
  width: 60%;
  display: flex;
  flex-direction: column;
}

.footer-top-left .footer-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footer-container .footer-top .footer-top-right {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.footer-top-right .footer-top-right-col {
  padding: 0rem 2rem 0;
  display: flex;
  flex-direction: column;
}

.footer-top-right .footer-top-right-col .caps {
  margin-bottom: 1rem;
}

.footer-top-right .footer-top-right-col a {
  text-decoration: none;
  line-height: 3;
}

.footer-top-right .footer-top-right-col a:hover {
  color: var(--tan);
}

.footer-bottom {
  padding: 1.5rem var(--side-padding);
  border-top: 2px solid var(--whiteGrey);
}

.footer-bottom-links {
  padding: 1.5rem var(--side-padding);
  border-top: 2px solid var(--whiteGrey);
}

.footer-bottom-links .footer-bottom-links-grid {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 10%;
  justify-content: space-around;
}

.footer-top-right-col .landing-mail {
  width: 30vw;
}

@media screen and (max-width: 600px) {
  .footer-container .footer-top {
    flex-direction: column;
    padding-top: 0;
    margin-bottom: 0;
  }
  .footer-top .footer-top-left {
    width: 100%;
  }

  .footer-top-right .footer-top-right-col {
    width: 100%;
    margin: 0;
    padding: 2rem 0;
  }

  .footer-top-right-col .landing-mail {
    width: 100%;
  }

  .footer-top-right-col .landing-mail button {
    font-size: 14px;
    min-width: 80px;
    padding: 3px 12px;
  }

  .footer-top-right .footer-top-right-col a {
    line-height: 2.5;
  }

  .footer-bottom-links .footer-bottom-links-grid {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
  }
  .footer-bottom-links .footer-bottom-links-grid a {
    margin: 5px 0;
  }
}

/* import tailwind directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* import othr css */
/* @import url("../node_modules/locomotive-scroll/dist/locomotive-scroll.css"); */

/* Global variables */
:root {
  /* colors */
  --faintGrey: #f6f6f6;
  --whiteGrey: #e5e5e5;
  --lightGrey: #b4bbbd;
  --grey: #5c6265;
  --darkGrey: #4a4d54;
  /* --primary: #3fc174; */
  --primary: #27ba62;
  --tan: #45494b;
  /* border radii */
  --border-radius-sm: 5px;
  --border-radius-lg: 10px;
  /* configs for header, paddings, etc */
  --header-height: 70px;
  --side-padding: 3rem;
}

/* Font styles */
@font-face {
  font-family: PJSXBold;
  src: url("./assets/fonts/PlusJakartaSans-ExtraBold.ttf");
}
@font-face {
  font-family: PJSBold;
  src: url("./assets/fonts/PlusJakartaSans-Bold.ttf");
}
@font-face {
  font-family: PJSMedium;
  src: url("./assets/fonts/PlusJakartaSans-Medium.ttf");
}
@font-face {
  font-family: PJS;
  src: url("./assets/fonts/PlusJakartaSans-Regular.ttf");
}

/* Default styles */
* {
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "PJSMedium", -apple-system, "Segoe UI", Verdana, Tahoma,
    sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  /* overflow-x: hidden; */
  min-height: 100vh;
  background-color: white;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

button {
  transition: 0.25s ease-out;
  min-height: 46px;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 16px;
  font-style: normal;
  font-weight: 300;
  font-size: 14.5px;
  line-height: 20px;
  font-family: PJSMedium;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
}

button.big {
  min-height: 52px;
  min-width: 120px;
  padding: 3px 21px;
  font-size: 16px;
  line-height: 26px;
}

button.tertiary-button {
  background-color: var(--tan);
  border-radius: var(--border-radius-sm);
  color: white;
  border: 2px solid var(--tan);
}

button.primary-button {
  background-color: var(--primary);
  border-radius: var(--border-radius-sm);
  color: white;
  border: 2px solid var(--primary);
}

button.secondary-button {
  background-color: transparent;
  border-radius: var(--border-radius-sm);
  color: var(--tan);
  font-weight: 500;
  border: 2px solid rgba(180, 187, 189, 0.4);
}

button:hover {
  /* filter: brightness(0.9); */
}

button.secondary-button:hover {
  /* background-color: var(--faintGrey); */
}

button:disabled {
  filter: grayscale(0.1);
}
/* 
button:active {
  transform: scale(0.99);
  transition: 0.25s ease-out;
} */

.bg-faint-grey {
  background-color: var(--faintGrey);
}

.bg-primary {
  background-color: var(--primary);
}

.faint-grey {
  color: var(--faintGrey);
}

.dark-grey {
  color: var(--darkGrey);
}

.grey {
  color: var(--grey);
}

.primary {
  color: var(--primary);
}

.tan {
  color: var(--tan);
}

.white {
  color: white;
}

/* .caps {
  font-family: PJSMedium;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 2px;
} */

.caps {
  font-family: PJSMedium;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
}

.normal-text-xlg {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 170%;
}

.normal-text-lg {
  font-style: normal;
  font-weight: 500;
  font-size: 16.2px;
  line-height: 170%;
}

.normal-text-sm {
  font-family: PJSMedium, sans-serif;
  font-size: 0.88rem;
  line-height: 180%;
  letter-spacing: 0.28px;
  /* font-weight: 300; */
}

.normal-text-xsm {
  font-family: PJS, sans-serif;
  font-size: 13.5px;
  line-height: 170%;
  letter-spacing: 0.3px;
  /* font-weight: 300; */
}

.h1 {
  font-family: PJSXBold, sans-serif;
  font-size: 2.1rem;
  line-height: 57px;
  z-index: 2;
  letter-spacing: -1px;
}

.h2 {
  font-family: PJSXBold, sans-serif;
  font-size: 1.6rem;
  line-height: 40px;
  z-index: 2;
  letter-spacing: -0.4px;
}

.h4 {
  font-family: PJSBold, sans-serif;
  font-size: 23px;
  line-height: 35px;
  letter-spacing: -0.005em;
}

.h6 {
  font-family: PJSBold, sans-serif;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.2px;
}

.h5 {
  font-family: PJSBold, sans-serif;
  font-size: 16.5px;
  line-height: 28px;
  letter-spacing: 0px;
}

.spacer-1 {
  width: 1rem;
}
.spacer-2 {
  width: 2rem;
}

.p-side {
  padding-left: var(--side-padding);
  padding-right: var(--side-padding);
}

.fancy-underline::after {
  content: "";
  background-color: rgb(242, 255, 2);
  height: calc(100% + 6px);
  display: block;
  position: absolute;
  width: calc(100% + 12px);
  left: -6px;
  bottom: calc(-2% - 3px);
  z-index: -1;
  border-radius: 2px;
}
.fancy-underline {
  position: relative;
  margin: 0 10px;
}

a.dotted-underline {
  text-decoration-color: var(--primary);
  text-decoration-line: underline;
  text-decoration-style: dashed;
  text-decoration-thickness: 1.5px;
  text-underline-offset: 7px;
}
/* 
html.has-scroll-smooth {
  backface-visibility: hidden;
  transform: translateZ(0);
}

[data-load-container] {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
} */

/* .has-scroll-smooth {
  overflow-y: scroll !important;
} */

input,
textarea {
  height: 44px;
  width: 100%;
  outline: 2.5px solid transparent;
  border: 1.5px solid var(--whiteGrey);
  padding: 0.5rem 0.8rem;
  border-radius: var(--border-radius-sm);
  transition: 0.25s ease-out;
  font-size: 14px;
  line-height: 1.8;
  letter-spacing: 0.4px;
  font-family: PJS;
}

input:focus,
textarea:focus {
  outline: 2.5px solid #3fc174aa;
  transition: 0.25s ease-out;
  border: 1.5px solid transparent;
}

input:placeholder,
textarea:placeholder {
  color: var(--grey);
}

textarea {
  height: 10rem;
}

.mob-only {
  display: none !important;
}

@media screen and (max-width: 600px) {
  :root {
    --border-radius-sm: 5px;
    --border-radius-lg: 10px;
    --header-height: 70px;
    --side-padding: 1rem;
  }

  .h1 {
    font-family: PJSBold, sans-serif;
    font-size: 1.4rem;
    line-height: 34px;
    letter-spacing: -0.6px;
    text-align: left;
  }

  .h2 {
    font-family: PJSXBold, sans-serif;
    font-size: 1.2rem;
    line-height: 30px;
    z-index: 2;
    letter-spacing: -0.4px;
  }

  .normal-text-sm {
    font-size: 13.5px;
  }

  .normal-text-lg {
    font-size: 14.5px;
  }

  button {
    min-height: 52px;
    font-size: 16px;
  }

  input,
  textarea {
    height: 50px;
    font-size: 15.5px;
    line-height: 1.8;
    letter-spacing: 0.4px;
    font-family: PJS;
  }

  textarea {
    height: 10rem;
  }

  .web-only {
    display: none;
  }

  .mob-only {
    display: unset !important;
  }
  .h5 {
    font-size: 16px;
  }
}

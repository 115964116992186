.contact-us-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0rem 0;
  position: relative;
  padding-top: calc(var(--header-height));
}

.contact-us-container .contact-us-left {
  width: 45%;
  height: calc(100vh - var(--header-height));
  position: sticky;
  top: var(--header-height);
  background-color: var(--faintGrey);
  padding: 4rem var(--side-padding);
}

.contact-us-container .contact-us-right {
  display: flex;
  flex-direction: column;
  padding: 3rem var(--side-padding);
  width: 55%;
}

.contact-us-container form {
  margin: 1rem 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1rem;
  column-gap: 1.5rem;
}

.contact-us-container form section:nth-child(5),
.contact-us-container form section:nth-child(6),
.contact-us-container form button {
  grid-column: 1 / 3;
}

.contact-us-container form label {
  color: var(--tan);
  font-family: PJSBold;
  line-height: 3;
  font-size: 0.9rem;
}

.contact-us-container .options-cont {
  display: flex;
  flex-wrap: wrap;
}

.options-cont .option {
  padding: 12px 16px;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  border: 1.5px solid var(--whiteGrey);
  margin-bottom: 1rem;
  cursor: pointer;
  transition: 0.25s ease-out;
}

.options-cont .option:hover {
  transition: 0.25s ease-out;
  background-color: var(--faintGrey);
}

.sq {
  width: 18px;
  border-radius: var(--border-radius-sm);
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--whiteGrey);
  margin-right: 1.5rem;
}

.sq.sq-active {
  background-color: var(--primary);
  border-color: var(--primary);
}

@media screen and (max-width: 600px) {
  .contact-us-container {
    padding: 0;
    flex-direction: column;
    padding-top: calc(var(--header-height) + 0rem);
  }

  .contact-us-container .contact-us-left {
    width: 100%;
    height: unset;
    padding: 2rem var(--side-padding);
    position: unset;
  }
  .contact-us-container .contact-us-right {
    width: 100%;
    padding-top: 0;
  }
  .contact-us-container form {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
  }
  .contact-us-container form section:nth-child(5),
  .contact-us-container form section:nth-child(6),
  .contact-us-container form button {
    grid-column: unset;
  }
  .contact-us-container form section:nth-child(2) label {
    display: none;
  }

  .options-cont .option {
    width: 100%;
    padding: 10px 14px;
  }
}

.landing-container {
  transform: translateY(calc(-1 * var(--header-height)));
  /* position: relative; */
  height: auto;
  display: flex;
  flex-direction: column;
  /* background-color: rebeccapurple; */
}

.landing-hero-bg {
  width: 100%;
  position: absolute;
  height: 100vh;
  padding-bottom: 3rem;
  top: calc(var(--header-height) * 1);
  left: 0;
  z-index: 9;
  background: url("../../assets/images/landing/gradientbg4.svg") no-repeat;
  background-size: 100% auto;
}

.landing-container .landing-hero-top {
  display: flex;
  flex-direction: row;
}

.landing-container .landing-hero-top-left {
  width: 55%;
  padding-right: 2rem;
}

.landing-container .landing-hero-top-right {
  flex: 1;
}

.landing-container .landing-companies {
  width: calc(100% + 5rem);
  margin-left: -2.5rem;
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 1rem;
  place-items: center;
}

.landing-companies img {
  width: 70%;
}

.landing-hero-section {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--header-height) var(--side-padding) 0;
}

.landing-hero-section .main-title {
  font-size: 39px;
  font-family: PJSXBold;
  letter-spacing: -0.5px;
  line-height: 130%;
  /* margin: 1rem 0; */
}

.landing-container .card {
  width: 24%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background-color: #ffffff6a;
  backdrop-filter: blur(2px);
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: 0.25s ease-out;
}

.landing-container .card:hover {
  transition: 0.25s ease-out;
  transform: scale(1.02);
}

.landing-container .card .h4 {
  font-size: 1.2rem;
  font-family: PJSBold;
  margin-top: 1rem;
}

.landing-container .card .desc {
  font-size: 13px;
}

.landing-container .mouse {
  width: 25px;
  height: 40px;
  background-color: var(--faintGrey);
  border-radius: 60px;
  position: absolute;
  bottom: 0.8rem;
  margin: 0 auto;
  margin-left: calc(50% - var(--side-padding));
  transform: translateX(-50%);
}
.landing-container .mouse::before {
  content: "";
  width: 6px;
  height: 10px;
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--tan);
  border-radius: 6px;
  opacity: 1;
  animation: wheel 2s infinite;
  -webkit-animation: wheel 2s infinite;
}

@keyframes wheel {
  to {
    opacity: 0;
    top: 30px;
  }
}

.landing-hero-top-right {
  /* transform: translateX(-30%); */
}

.uses-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(100vh + 2rem);
  width: 100%;
  padding: 0 var(--side-padding);
  padding-top: 3rem;
}

.uses-container .uses-inner-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.uses-container .uses-inner-container .uses-item-container {
  width: calc((100% - 12rem) / 3);
  margin: 0 0;
  border: 1.5px solid var(--faintGrey);
  background-color: var(--faintGrey);
}

.uses-container .uses-inner-container .uses-item-container .use-index {
  font-size: 2rem;
  color: #ababab9a;
  font-family: PJSXBold;
}

.uses-container .uses-inner-container .uses-item-container .use-title {
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1.1px;
  margin: 0.5rem 0;
  font-family: PJSXBold;
}

.uses-container
  .uses-inner-container
  .uses-item-container
  .use-title::first-letter {
  font-size: 1.3rem;
}

.landing-testimonial-container {
  width: 100%;
  position: relative;
  margin: 4rem 0;
  padding: 2rem 0;
  max-height: 140vh;
  overflow: hidden;
  margin-top: 5rem;
}

.landing-testimonial-container .landing-testimonial-container-bg {
  background-image: url("../../assets/images/landing/dots-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  width: 100%;
  z-index: 9;
  height: 200px;
  top: 0;
  left: 0;
}

.landing-testimonial-container .landing-testimonial-container-white-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(to bottom, #00000000 50%, #ffffff);
  z-index: 10;
}

.landing-testimonial-container .landing-testimonial-container-inner {
  padding: 5rem var(--side-padding);
}

.landing-container
  .landing-testimonial-container-inner
  .landing-testimonial-container-grid {
  width: 100%;
  margin-top: 4rem;
  display: flex;
}

.landing-container
  .landing-testimonial-container-inner
  .landing-testimonial-container-grid
  .landing-testimonial-grid-item {
  flex: 1;
}

.landing-container .landing-testimonial-floating-button {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  height: 40px;
}

.landing-container .landing-testimonial-floating-button button {
  border-radius: var(--border-radius-lg);
  border-color: var(--whiteGrey);
  border-width: 1px;
  background-color: white;
  font-family: PJSMedium;
}

.landing-container .founder-container {
  width: 100%;
  padding: 3rem var(--side-padding);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-container .founder-container .note {
  font-family: PJSBold;
  font-size: 1.2rem;
  line-height: 1.7;
  text-align: center;
  margin: 3rem 10vw;
}

.landing-container .features-container {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 4rem var(--side-padding);
  /* margin-top: 180vh; */
  background-color: var(--faintGrey);
}

.features-container .features-left {
  width: 35%;
  display: flex;
  flex-direction: column;
}

.features-container .features-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  flex: 1;
}

.video-overlay {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-video {
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.landing-container
  .landing-hero-section
  .landing-video
  .video-overlay
  .play-button {
  width: 4rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  height: 4rem;
  transition: 0.25s ease-out;
  cursor: pointer;
}

.play-button-web {
  padding: 8px 16px;
  padding-left: 8px;
  background-color: #7c7c7c3a;
  display: flex;
  align-items: center;
  border-radius: 50px;
  transition: 0.2s ease-out;
}

.landing-container
  .landing-hero-section
  .landing-video
  .video-overlay
  .play-button-web
  .play-button {
  width: 2rem;
  opacity: 0.9;
  height: 2rem;
  margin-right: 10px;
}

.play-button-web:hover {
  background-color: #0000006a;
  transition: 0.2s ease-out;
}

.landing-container
  .landing-hero-section
  .landing-video
  .video-overlay
  .play-button:hover {
  transform: scale(1.03);
  transition: 0.25s ease-out;
}

.landing-container
  .landing-hero-section
  .landing-video
  .video-overlay
  .play-button
  img {
  width: 30%;
  object-fit: cover;
  cursor: pointer;
  margin-left: 5px;
  transition: 0.25s ease-out;
}

.landing-container
  .landing-hero-section
  .landing-video
  .video-overlay
  .play-button-web
  .play-button
  img {
  width: 30%;
  object-fit: cover;
  cursor: pointer;
  margin-left: 2px;
  transition: 0.25s ease-out;
}

.landing-container
  .landing-hero-section
  .landing-video
  .video-overlay
  .play-button:hover
  img {
  transform: scale(1.03);
  transition: 0.25s ease-out;
}

.process-flow {
  width: calc(100% - 2 * var(--side-padding));
  display: flex;
  flex-direction: column;
  margin: 2rem var(--side-padding);
  /* position: sticky; */
  /* height: 160vh; */
  /* top: var(--header-height); */
  cursor: pointer;
}

.process-flow-sticky {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* position: sticky; */
  /* top: calc(var(--header-height) + 5rem); */
}

.process-flow .process-flow-inner {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: space-between;
}

.process-flow .process-flow-left {
  width: 70%;
  /* position: sticky; */
  top: calc(2 * var(--header-height) + 3rem);
  margin-left: 2rem;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  height: calc(100vh - var(--header-height) - 11rem);
  background-image: url("../../assets/images/landing/gradientbg2.svg");
  background-size: 100% auto;
  background-repeat: no-repeat;
  /* overflow: hidden; */
}

.process-flow .process-flow-left .process-flow-left-inner {
  width: 300%;
  padding: 5rem;
  display: flex;
  align-items: center;
  /* background-color: firebrick; */
  height: 100%;
}

.process-flow-left img {
  width: 300%;
  max-width: unset;
  transition: 0.3s ease-out;
}

.process-flow-left img.process-flow-img-1 {
  transform: translateX(-30%);
}

.process-flow-left img.process-flow-img-2 {
  transform: translateX(-63%);
}

.process-flow .process-flow-right {
  flex: 1;
  display: flex;
  /* position: sticky; */
  top: calc(2 * var(--header-height) + 2rem);
  flex-direction: column;
  justify-content: space-between;
}

.process-flow .process-flow-right .process-flow-box {
  padding: 1.5rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  transition: 0.25s ease-out;
}

.process-flow .process-flow-right .process-flow-box.active {
  padding: 1.5rem;
  border-radius: 5px;
  background-color: var(--faintGrey);
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}

.process-flow .process-flow-right .process-flow-box h1 {
  font-family: PJSBold;
  font-size: 1.15rem;
  color: var(--tan);
  margin-bottom: 0.5rem;
  letter-spacing: 0px;
  opacity: 0.7;
}

.process-flow .process-flow-right .process-flow-box.active h1 {
  font-family: PJSXBold;
  opacity: 1;
}

.process-flow .process-flow-right .process-flow-box p {
  font-family: PJSMedium;
  font-size: 0.9rem;
  color: var(--tan);
  line-height: 1.7;
  opacity: 0.6;
  letter-spacing: 0.2px;
}

.process-flow .process-flow-right .process-flow-box.active p {
  opacity: 0.8;
}

.meet-team-container {
  width: calc(100%);
  display: flex;
  padding: 2rem var(--side-padding);
  padding-bottom: 6rem;
}

.meet-team-container .meet-team-left {
  width: 35%;
  padding-right: 3rem;
  display: flex;
  flex-direction: column;
}

.meet-team-container .meet-team-right {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem;
  flex: 1;
  padding-top: 4rem;
}

.meet-team-container .meet-team-right .team-member-box {
  display: flex;
  align-items: center;
}
.meet-team-container .meet-team-right .team-member-box img {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1.4rem;
}

.landing-marquee {
  /* background-color: rebeccapurple; */
}

.landing-marquee .marquee-content {
  display: flex;
  animation: scrolling 40s linear infinite;
}

.landing-marquee .marquee-inner {
  position: absolute;
  width: calc(100% + 2rem);
  margin-left: -1rem;
  top: 0;
  height: 100px;
  z-index: 10;
  background: linear-gradient(
    to right,
    #12d76206,
    #00000000 25%,
    #00000000 75%,
    #fff
  );
}

@keyframes scrolling {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translatex(-200vw);
  }
}

.landing-marquee .marquee-content img {
  flex: 0 0 11rem;
  margin: 0 2rem;
}

.landing-fancy-stat {
  width: calc(100% - 2 * var(--side-padding));
  margin: 2rem var(--side-padding);
  margin-top: 4rem;
  display: flex;
}

.landing-fancy-stat .lfs-left {
  width: 40%;
  max-height: 70vh;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 2rem;
  background-image: url("../../assets/images/landing/lfs-image.svg");
  background-size: cover;
}

.landing-fancy-stat .lfs-left svg {
  margin-bottom: 1.5rem;
}

.landing-fancy-stat .lfs-right {
  flex: 1;
  padding-left: 3rem;
  display: flex;
  flex-direction: column;
}

.lfs-right .lfs-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* grid-gap: 2rem; */
}

.lfs-grid div {
  border: 1px solid var(--whiteGrey);
  padding: 1rem;
}

.landing-map {
  width: calc(100% - 2 * var(--side-padding));
  margin: 2rem var(--side-padding);
  margin-top: 4rem;
  display: flex;
}

.landing-map .landing-map-left {
  flex: 1;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
}

.landing-map .landing-map-right {
  width: 45%;
  margin-right: 1rem;
  display: flex;
  justify-content: flex-end;
  /* background-color: firebrick; */
}
.landing-map .landing-map-right-inner {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 506px;
  height: 493px;
  /* background-color: lightcoral; */
}

.landing-map-right img {
  object-fit: cover;
}

.landing-map-right-inner area {
  background-color: var(--primary);
}

.landing-map .map-point-cont {
  position: absolute;
  padding: 5px;
  cursor: pointer;
  border-radius: 50%;
  /* background-color: darkmagenta; */
}
.landing-map .map-point-cont.active-pt {
  background-color: var(--primary);
}

.landing-map .map-point-cont .map-point-inner {
  position: relative;
}

.landing-map .map-point-cont .map-point {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--primary);
  cursor: pointer;
}

.landing-map .map-point-cont .map-point-inner .map-info {
  position: absolute;
  bottom: 20px;
  transform: translateX(-90%);
  padding: 0.7rem;
  box-shadow: 2px 2px 10px #00000024;
  width: 180px;
  visibility: visible;
  /* transition: visibility 0.3s ease-out; */
  z-index: 10;
  /* display: none; */
  background-color: #ffffffad;
  /* backdrop-filter: blur(0px); */
  border-radius: 6px;
}

.landing-map ul {
  list-style-type: disc;
  padding-inline-start: 20px;
}

.landing-map .arrow-map {
  position: absolute;
  width: 232px;
  left: -135px;
  top: 294px;
}

.landing-map li {
  font-size: 13.5px;
}

.landing-map .map-point-cont .map-point-inner .map-info.map-no-visible {
  visibility: hidden;
  /* transition: visibility 0.3s ease-out; */
}

.landing-mail {
  position: relative;
}

.landing-mail form {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.landing-mail .landing-email {
  height: 52px;
  border-radius: 5px;
  padding: 8px 16px;
  background-color: white;
  outline: none;
  letter-spacing: 0.2px;
  border: 2px solid var(--primary);
  color: var(--tan);
  font-size: 14.5px;
  flex: 1;
}

.landing-mail .pb {
  position: absolute;
  right: 0;
  top: 0;
  /* background-color: var(--faintGrey); */
  border-top-left-radius: 0;
  border-left: 0;
  border-bottom-left-radius: 0;
}

.landing-mail .landing-email::placeholder {
  color: var(--tan);
  opacity: 0.6;
}

.green-section {
  width: 100%;
  padding: 3rem var(--side-padding);
  display: flex;
  flex-direction: column;
  margin: 0 0;
  overflow: hidden;
  background-color: #1e2026;
  margin-bottom: -70px;
}

.pain-points {
  width: calc(100%);
  display: flex;
  flex-wrap: wrap;
  margin: 2rem 0;
  /* margin-left: calc(-3 * var(--side-padding)); */
}

.pain-points .pain-point {
  background-color: #ffffff19;
  border-radius: 20px;
  height: 40px;
  display: flex;
  margin: 0.7rem 0;
  align-items: center;
  padding: 10px 20px;
}

.green-section .landing-fancy-stat {
  margin: 0;
  margin-top: 3rem;
  width: 100%;
  flex-direction: row-reverse;
}

.green-section .landing-fancy-stat .lfs-left {
  margin-right: 0;
  padding-right: 0;
  width: 50%;
  padding: 2rem;
}

.green-section .landing-fancy-stat .lfs-right {
  padding-left: 0;
  padding-right: 3rem;
}

@media only screen and (max-width: 600px) {
  .landing-container .landing-hero-banner {
    display: none;
  }
  .mouse {
    display: none;
  }
  .landing-container .landing-hero-section .landing-hero-top {
    flex-direction: column;
  }
  .landing-hero-section {
    align-items: center;
  }
  .landing-hero-bg {
    background-position: center top;
    background-size: 300% auto;
    height: 100vh !important;
  }
  .landing-container
    .landing-hero-section
    .landing-hero-top
    .landing-hero-top-right {
    display: none;
  }
  .landing-container
    .landing-hero-section
    .landing-hero-top
    .landing-hero-top-left {
    align-items: center;
    padding: 1.5rem 0;
    padding-top: 2rem;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    padding-bottom: 0;
  }
  .landing-container
    .landing-hero-section
    .landing-hero-top
    .landing-hero-top-left
    .main-title {
    font-size: 22px;
    text-align: center;
  }
  .landing-container
    .landing-hero-section
    .landing-hero-top
    .landing-hero-top-left
    p {
    text-align: center;
  }
  .landing-container
    .landing-hero-section
    .landing-hero-top
    .landing-hero-top-left
    .flex.items-center {
    flex-direction: column;
    width: 100%;
  }
  .landing-container
    .landing-hero-section
    .landing-hero-top
    .landing-hero-top-left
    .flex.items-center
    button {
    width: calc(100vw - 2 * var(--side-padding));
    margin-bottom: 0.7rem;
  }

  .uses-container {
    align-items: flex-start;
    margin-top: calc(100vh + var(--header-height) + 0rem);
  }

  .uses-container .h1 {
    text-align: left;
  }

  .uses-inner-container {
    flex-direction: column;
    align-items: flex-start;
    justify-content: unset;
    margin-top: 0rem;
  }
  .uses-container .uses-inner-container .uses-item-container {
    width: 100%;
    margin: 0.35rem 0;
    margin-bottom: 1rem;
    padding: 1rem !important;
  }
  .uses-container .uses-inner-container .uses-item-container .use-index {
    font-size: 1.5rem;
  }
  .uses-container .uses-inner-container .uses-item-container .use-title {
    font-size: 1rem;
    text-align: left;
    margin-top: -2rem;
    margin-left: 34px;
    margin-bottom: 0.5rem;
    opacity: 0.9;
  }

  .landing-testimonial-container {
    margin-top: 10px;
  }
  .landing-testimonial-container .landing-testimonial-container-bg {
    background-size: 200% auto;
  }
  .landing-testimonial-container .h1 {
    text-align: center !important;
  }
  .landing-container
    .landing-testimonial-container-inner
    .landing-testimonial-container-grid {
    flex-direction: column;
    margin-top: 2rem;
  }
  .landing-testimonial-container-grid .landing-testimonial-item {
    padding: 1rem;
    margin-bottom: var(--side-padding);
  }
  .landing-container .landing-testimonial-floating-button {
    width: 100%;
  }
  .landing-container .landing-testimonial-floating-button button {
    width: calc(100% - 4rem);
    margin: 0 2rem;
  }

  .landing-container .features-container {
    flex-direction: column;
    padding: 2rem var(--side-padding);
  }
  .features-container .features-left {
    width: 100%;
    margin-bottom: 0rem;
  }
  .features-container .features-grid {
    grid-gap: 1rem;
    grid-template-columns: repeat(1, 1fr);
  }
  .features-container .features-grid .text-md {
    font-size: 16.5px;
    margin-top: -5px;
  }
  .features-container .features-grid .text-sm {
    font-size: 14px;
  }

  .landing-container .founder-container {
    align-items: flex-start;
  }
  .landing-container .founder-container .note {
    margin: 2rem 0;
    font-size: 1.05rem;
    line-height: 1.8;
    text-align: left;
  }
  .process-flow {
    display: none;
  }
  .meet-team-container {
    flex-direction: column;
    padding: 1rem var(--side-padding);
  }
  .meet-team-container .meet-team-left {
    width: 100%;
    padding-right: 2rem;
  }
  .meet-team-container .meet-team-right {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    padding-bottom: 1rem;
    grid-gap: 1.5rem;
    padding-top: 1.5rem;
  }
  .meet-team-container .meet-team-right .team-member-box img {
    width: 3rem;
    height: 3rem;
  }
  .landing-fancy-stat {
    flex-direction: column;
    margin-top: 2rem;
  }
  .landing-fancy-stat .lfs-left {
    width: 100%;
    padding: 1.5rem 1rem;
  }
  .landing-fancy-stat .lfs-right {
    width: 100%;
    padding-left: 0;
    margin-top: 3rem;
  }
  .landing-fancy-stat .lfs-right .lfs-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .green-section .landing-fancy-stat {
    flex-direction: column;
    margin-top: 0rem;
  }
  .green-section .landing-fancy-stat .lfs-left {
    width: 100%;
    padding: 1.5rem 1rem;
  }
  .green-section .landing-fancy-stat .lfs-right {
    width: 100%;
    padding-left: 0;
    margin-top: 3rem;
  }
  .green-section .landing-fancy-stat .lfs-right .lfs-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .landing-map {
    flex-direction: column;
    overflow: hidden;
    margin: 1rem var(--side-padding);
  }
  .landing-map .landing-map-left {
    width: 100%;
    padding-right: 0;
  }
  .landing-map .landing-map-right {
    width: calc(100% + 4rem);
    transform: translateX(-2rem);
    padding-right: 0;
    margin-right: 0;
    margin-top: 1rem;
  }
  .landing-map .map-point-cont .map-point-inner .map-info {
    transform: translateX(-50%);
  }
  .landing-map .landing-map-right-inner {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: unset;
  }
  .play-button-mob {
    padding: 8px 16px;
    padding-left: 8px;
    background-color: #7c7c7c3a;
    display: flex;
    align-items: center;
    border-radius: 50px;
    transition: 0.2s ease-out;
  }

  .landing-container
    .landing-hero-section
    .landing-video
    .video-overlay
    .play-button-mob
    .play-button {
    width: 1.5rem;
    opacity: 0.9;
    height: 1.5rem;
    margin-right: 10px;
  }

  .play-button-mob:hover {
    background-color: #0000006a;
    transition: 0.2s ease-out;
  }
  .landing-container
    .landing-hero-section
    .landing-video
    .video-overlay
    .play-button-mob
    .play-button
    img {
    width: 30%;
    object-fit: cover;
    cursor: pointer;
    margin-left: 2px;
    transition: 0.25s ease-out;
  }
  .green-section {
    margin-top: 0;
  }
}
